import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SendIcon from '@mui/icons-material/Send';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const theme = createTheme();

export default function SignUp() {

  const [open, setOpen] = React.useState(false);
  const [successMsg, setSuccessMsg] = React.useState('none');
  const [errorMsg, setErrorMsg] = React.useState('none');
  const [formDisplay, setFormDisplay] = React.useState('block');
  const [nameError, setNameError] = React.useState({ status: false, msg: null });
  const [emailError, setEmailError] = React.useState({ status: false, msg: null });
  const [subjectError, setSubjectError] = React.useState({ status: false, msg: null });
  const [messageError, setMessageError] = React.useState({ status: false, msg:null });

  const handleValidation = (name, email, subject, message) => {
    let formIsValid = true;

    function checkName(name) {
      //Name
      if (!name) {
        formIsValid = false;
        setNameError({ status: true, msg: 'Cannot be empty' });
      } else if (typeof name !== "undefined") {
        if (!name.match(/^[a-zA-Z]+(\s[a-zA-Z]+)?$/)) {
          formIsValid = false;
          setNameError({ status: true, msg: 'Only letters' });
        } else {
          setNameError({ status: false, msg: null });
        };
      }
    };

    function checkEmail(email) {
      //Email
      if (!email) {
        formIsValid = false;
        setEmailError({ status: true, msg: 'Cannot be empty' });
      } else if (typeof email !== "undefined") {
        let lastAtPos = email.lastIndexOf("@");
        let lastDotPos = email.lastIndexOf(".");

        if (
          !(
            lastAtPos < lastDotPos &&
            lastAtPos > 0 &&
            email.indexOf("@@") === -1 &&
            lastDotPos > 2 &&
            email.length - lastDotPos > 2
          )
        ) {
          formIsValid = false;
          setEmailError({ status: true, msg: 'Email is not valid' });
        } else {
          setEmailError({ status: false, msg: null });
        }
      }
    };

    function checkSubject(subject) {
      //Name
      if (!subject) {
        formIsValid = false;
        setSubjectError({ status: true, msg: 'Cannot be empty' });
      } else if (typeof subject !== "undefined") {
        if (subject.match(/^[\s]+$/)) {
          formIsValid = false;
          setSubjectError({ status: true, msg: 'Enter a valid subject' });
        } else {
          setSubjectError({ status: false, msg: null });
        };
      }
    };

    function checkMessage(message) {
      //Name
      if (!message) {
        formIsValid = false;
        setMessageError({ status: true, msg: 'Cannot be empty' });
      } else if (typeof message !== "undefined") {
        if (message.match(/^[\s]+$/)) {
          formIsValid = false;
          setMessageError({ status: true, msg: 'Enter a valid message' });
        } else {
          setMessageError({ status: false, msg: null });
        };
      }
    };

    checkName(name);
    checkEmail(email);
    checkSubject(subject);
    checkMessage(message);

    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    var valStatus = handleValidation(data.get('name'), data.get('email'), data.get('subject'), data.get('message'));
    if (valStatus) {
      setOpen(true);

      const msgData = {
        name: data.get('name'),
        email: data.get('email'),
        subject: data.get('subject'),
        message: data.get('message')
      };

      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(msgData)
      };
      fetch('https://api.rkv.one/api/message?code=6I2tg2GpDUGvu8E4duWB66AMU6wYVm3Ct2apoQQstNOI5wC2n3RUTw==', requestOptions)
        .then(response => {
          return response.text()
        })
        .then((response) => {
          setSuccessMsg('block');
          setFormDisplay('none');
          setOpen(false);
        })
        .catch((err) => {
          setErrorMsg('block');
          setFormDisplay('none');
          setOpen(false);
        });
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs" sx={{ bgcolor: 'white', borderRadius: '20px', padding: '40px!important', marginTop: '40px', marginBottom: '40px'}}>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Backdrop
            sx={{color: "FFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Avatar sx={{ m: 1, backgroundColor: '#000000' }}>
            <SendIcon />
          </Avatar>
          <Typography variant="h6" gutterBottom component="div" sx={{ color: '#000000', display: successMsg}}>
            Message sent successfully!
          </Typography>
          <Typography variant="h6" gutterBottom component="div" sx={{ color: '#000000', display: errorMsg}}>
            Oops! Something went wrong.
          </Typography>
          <Typography component="h1" variant="h5" sx={{ color: '#000000', display: formDisplay}}>
            Send me your message!
          </Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3, display: formDisplay}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="name"
                  name="name"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  autoFocus
                  size="small"
                  error={nameError.status}
                  helperText={nameError.msg}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  size="small"
                  error={emailError.status}
                  helperText={emailError.msg}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="subject"
                  label="Subject"
                  type="text"
                  id="subject"
                  size="small"
                  error={subjectError.status}
                  helperText={subjectError.msg}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="message"
                  label="Message"
                  type="text"
                  id="message"
                  multiline
                  rows={4}
                  size="small"
                  error={messageError.status}
                  helperText={messageError.msg}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 6, mb: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}