import React, { Component } from 'react';
import '../error404/style.css';
import ReactGA from 'react-ga';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import Astronaut from '../../assets/img/astronaut.svg';
import Planet from '../../assets/img/planet.svg';
import particlesJs from '../../const/404_particle.js';
import Particles from "react-tsparticles";

class Error404 extends Component {

  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  constructor(props) {
    super(props);

    this.particlesInit = this.particlesInit.bind(this);
    this.particlesLoaded = this.particlesLoaded.bind(this);
  }

  particlesInit(main) {
    console.log(main);

    // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
  }

  particlesLoaded(container) {
    console.log(container);
  }

  render() {

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Page not found! | Vansh Gupta</title>
          <meta name="description" content="Vansh Gupta - Frontend Developer | Graphics Designer" />
        </Helmet>
        
        <div className="permission_denied">
          <Particles
            id="tsparticles"
            init={this.particlesInit}
            loaded={this.particlesLoaded}
            options={particlesJs}
          />
          <div className="denied__wrapper">
              <h1>404</h1>
              <h3>LOST IN <span>SPACE</span> rkv.one? Hmm, looks like that page doesn't exist.</h3>
              <img id="astronaut" src={Astronaut} alt="Astronaut"/>
              <img id="planet" src={Planet} alt="Planet" />
            <Link to="/home"><button className="denied__link">Go Home</button></Link>
          </div>
        </div>
      </div>
        
      // <div>
      //   <Helmet>
      //     <meta charSet="utf-8" />
      //     <title>Page not found! | Vansh Gupta</title>
      //     <meta name="description" content="Vansh Gupta - Frontend Developer | Graphics Designer" />
      //   </Helmet>
      //   <div className="Loading-header">
      //     <PuffLoader
      //       size={'40vw'}
      //       color={"#fff"}
      //       loading={true}
      //     />
      //   </div>
      // </div>
    )
    
  }
}

export default Error404;
