import React from 'react';
import '../hamburger_menu/style.css';
import {
  Link
 } from "react-router-dom";

function Hamburger() {
  return (
    <div className="menu-wrap">
      <input type="checkbox" className="toggler" />
        <div className="hamburger">
          <div>
            
          </div>
          </div>
          <div className="menu">
            <div>
            <div>
                <ul>
                  <li className="hamburger-items"><Link to="/home">HOME</Link></li>
                  <li className="hamburger-items"><a href="https://www.linkedin.com/in/rdhksnvns" target="_blank" rel="noopener noreferrer">About</a></li>
                  <li className="hamburger-items"><Link to="/contact">CONTACT</Link></li>
                  <li className="hamburger-items"><a href="https://github.com/rdhksnvns" target="_blank" rel="noopener noreferrer">Github</a></li>
                  <li className="hamburger-items"><Link to="/apps">APPS</Link></li>
                </ul>
               </div>
             </div>
            </div>
         </div>
  );
}

export default Hamburger;
