import React from 'react';
import '../home_left/style.css';
import {Container, Row, Col} from 'react-bootstrap';
import { Link } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { blue, grey } from '@mui/material/colors';

  function Home_Left() {
  return (
    <Container className="home-left" >
      <Row className="home-left-main">
        <Col xl={12} className="home-left-main-col">
          <h1 className="first-line">
            Hello! 
          </h1>
          <h2 className="second_line">
            I'm <strong>Vansh Gupta</strong>
          </h2>
          
          <br />
            
          <Stack spacing={2} direction="row" justifyContent="center" alignItems="center">
            <Button variant="contained" size="large" href="https://www.linkedin.com/in/rdhksnvns" sx={{
              backgroundColor: '#ffffff',
              color: blue[700],
              width: "150px",
              '&:hover': {
                backgroundColor: grey[200],
              },
              }}>About Me</Button>
            <Link to="/contact" style={{ color: "#FFFFFF", textDecoration: "none" }}><Button variant="contained" size="large" sx={{marginTop: "-4.25px", width: "150px", '&:hover': { color: blue[700], backgroundColor: '#FFFFFF' } }}>Contact Me</Button></Link>
          </Stack>

          <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '15px' }}>
            <Link to="/resume" style={{ color: "#FFFFFF", textDecoration: "none" }}><Button variant="contained" size="large" sx={{marginTop: "-4.25px", width: "150px", '&:hover': { color: blue[700], backgroundColor: '#FFFFFF' } }}>Resume</Button></Link>
            <Link to="/apps" style={{ color: "#FFFFFF", textDecoration: "none" }}><Button variant="contained" size="large" sx={{marginTop: "-4.25px", width: "150px", backgroundColor: '#ffffff', color: blue[700], '&:hover': { backgroundColor: grey[200] } }}>All Apps</Button></Link>
          </Stack>
          
          <Stack spacing={2} direction="row" justifyContent="center" alignItems="center" sx={{ marginTop: '15px' }}>
            <Button variant="contained" size="large" href="https://github.com/rdhksnvns" sx={{
              backgroundColor: '#212121',
              color: '#FFFFFF',
              width: "150px",
              '&:hover': {
                backgroundColor: grey[200],
              },
            }}>Github</Button>
          </Stack>
        </Col>
      </Row>
    </Container>
  );
}

export default Home_Left;
