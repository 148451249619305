import React, { Component } from 'react';
import '../loading/style.css';
import { Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';
import {Helmet} from 'react-helmet';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Rkv from '../../assets/img/s.svg';

class Loading extends Component {

  state = {
    redirect: false
  }

  componentDidMount() {
     this.id = setTimeout(() => this.setState({ redirect: true }), 3000);
  }
    
  componentWillUnmount() {
    clearTimeout(this.id)
  }

  render() {
    return this.state.redirect
      ? <Redirect to="/home" />
      : <div>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Vansh Gupta</title>
                <link rel="canonical" href="https://rkv.one" />
                <meta name="description" content="Vansh Gupta - Frontend Developer | Graphics Designer" />
          </Helmet>
          <div className="Loading-header">
            <Box id="loaderBox" sx={{ width: '20%', display: 'block' }}>
              <img id="rkv" src={Rkv} alt="rkv" style={{width: "80%", marginBottom: "10%"}} />
              <LinearProgress />
            </Box>
          </div>
        </div>
  }
}

export default Loading;
